<template>
  <div id="templateListPage" class="mt-3 px-4 sm:px-6 lg:px-8">
    <div class="px-4 sm:px-6 lg:px-8">
      <!-- Header -->
      <div class="flex">
        <div class="flex-1">
          <div class="pt-0 text-2xl font-medium">
            WhatsApp Templates
            <span v-if="!loading">({{ pageOptions.total }})</span>
          </div>
          <!-- <el-select @change="resetPaginationAndSearch" class="statusFilter" size="small" v-model="filters.status" multiple collapse-tags placeholder="Template Status"> -->
          <!-- <el-option v-for="item in filterOptions.status" :key="item.value" :label="item.label" :value="item.value"> </el-option> -->
          <!-- </el-select> -->
        </div>

        <div class="flex-none pr-3">
          <el-input class="w-40" prefix-icon="el-icon-search" size="small" v-model="filters.name" @input="onSearch" placeholder="Search Template"> </el-input>
        </div>

        <el-select @change="resetPaginationAndSearch" class="flex-none" size="small" v-model="filters.status">
          <el-option label="All Templates" value=""> </el-option>
          <el-option label="Pending Templates" value="submitted"> </el-option>
          <el-option label="Approved Templates" value="approved"> </el-option>
          <el-option label="Rejected Templates" value="rejected"> </el-option>
        </el-select>

        <div class="flex-none pl-2">
          <el-button type="primary" size="small" @click="syncWithFacebook" :loading="syncingWithFacebook" plain>Sync With Facebook </el-button>
          <router-link :to="{ path: '/templates/create' }">
            <el-button type="primary" size="small">Create Template</el-button>
          </router-link>
          <!-- <el-button type="warning" size="small" :loading="creatingCampaign" v-if="isBalanceButtonVisible()" @click="$refs.channelBalance.showPopup()"> Check Balance </el-button> -->
          <!-- <el-button type="primary" size="small" :loading="creatingCampaign" @click="onCreateCampaignClick"> Create {{ channel }} Campaign </el-button> -->
        </div>
      </div>

      <!-- Loader -->
      <div v-if="loading" class="w-full bg-white border rounded-lg mt-2 overflow-hidden" style="height: 60vh" v-loading="true"></div>

      <div v-else class="mt-4 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300" style="table-layout: fixed">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6">Template Name</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">Status</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">Account</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">Category</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">Preview</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">Language</th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-if="templateList && templateList.length == 0">
                    <td class="py-60 text-center text-gray-500" colspan="6">No Templates Found.</td>
                  </tr>

                  <template v-else>
                    <tr v-for="(template, index) in templateList" :key="index">
                      <td class="w-12 overflow-hidden py-4 pl-4 pr-3 text-xs font-medium text-gray-900">{{ template.name }}</td>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <span v-if="template.status == 'approved'" class="inline-flex items-center rounded-full bg-green-600 px-2.5 py-0.5 text-xs font-medium text-white">{{ template.status }}</span>
                        <span v-else-if="template.status == 'rejected'" class="inline-flex items-center rounded-full bg-red-600 px-2.5 py-0.5 text-xs font-medium text-white">{{ template.status }}</span>
                        <span v-else class="inline-flex items-center rounded-full bg-gray-600 px-2.5 py-0.5 text-xs font-medium text-white">{{ template.status }}</span>
                      </th>
                      <td scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">{{ template.channel?.name }}</td>
                      <td class="w-24 px-3 py-4 text-xs text-gray-500">{{ template.category }}</td>
                      <td class="max-w-md px-3 py-4 text-xs text-gray-500" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ template.body }}</td>
                      <td class="w-10 whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{ template.language }}</td>
                      <td class="w-10 relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <el-tooltip content="Preview Template">
                          <i class="fa-regular fa-eye text-primary-600 cursor-pointer" @click="previewTemplate(template)"></i>
                        </el-tooltip>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="7">
                        <div class="pagination font-semibold">
                          <div class="totalCount">Total {{ pageOptions.total }} Templates</div>
                          <el-pagination background @current-change="fetchTemplates" :current-page.sync="pageOptions.pageNumber" :page-size="pageOptions.pageSize" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Template Preview Dialog -->
    <el-dialog title="Template Preview" :visible.sync="showPreviewDialog" width="500px">
      <TemplateInputWithPreview onlyPreview="true" ref="TemplateInputWithPreview"></TemplateInputWithPreview>
    </el-dialog>
  </div>
</template>

<style lang="scss" src="./templateList.scss"></style>

<script>
import templateListComponent from './templateListComponent';
export default templateListComponent;
</script>
