import templateService from '@/services/templateService';
const _ = require('lodash');
import TemplateInputWithPreview from '@/components/sendTemplateMessagePopup/templateInputWithPreview/templateInputWithPreview';

export default {
  name: 'TemplateList',
  components: {
    TemplateInputWithPreview
  },
  data() {
    return {
      loading: false,
      syncingWithFacebook: false,
      showPreviewDialog: false,

      // Filters
      filters: {
        name: '',
        status: ''
      },

      pageOptions: {
        pageSize: 20,
        pageNumber: 1,
        total: null
      },

      templateList: null
    };
  },

  methods: {
    onSearch: _.debounce(function () {
      this.resetPaginationAndSearch();
    }, 700),

    resetPaginationAndSearch() {
      this.pageOptions.pageNumber = 1;
      this.fetchTemplates(true);
    },

    async fetchTemplates(fetchCount) {
      try {
        this.loading = true;

        let params = {
          fetchCount: fetchCount,
          filters: this.filters,
          pageOptions: this.pageOptions
        };

        let response = await templateService.getList(params);

        this.templateList = response.data.list;
        if (fetchCount) this.pageOptions.total = response.data.count;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to fetch templates. Please contact support.');
      } finally {
        this.loading = false;
      }
    },

    async previewTemplate(template) {
      this.showPreviewDialog = true;
      requestAnimationFrame(() => {
        this.$refs.TemplateInputWithPreview.applyTemplate(template, template.variables);
      });
    },

    async syncWithFacebook() {
      try {
        this.syncingWithFacebook = true;

        await templateService.syncWithFacebook({});

        this.successToast('Sync Successful.');
        this.fetchTemplates();
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to sync templates. Please contact support.');
      } finally {
        this.syncingWithFacebook = false;
      }
    },

    async integrateDialog360() {
      let url = 'https://hub.360dialog.com/dashboard/app/Myk2TUPA/permissions';
      const windowFeatures = 'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
      open(url, 'integratedOnboardingWindow', windowFeatures);
    }
  },

  mounted() {
    this.fetchTemplates(true);
  }
};
